<template>
  <div>
    <el-card>
      <img :src="userInfo.avatar" alt="" />
      <div>
        <span>用户名: </span>
        <span> {{ userInfo.username }}</span>
      </div>
      <div>
        <span>手机: </span>
        <span> {{ userInfo.phone }}</span>
      </div>
      <div>
        <span>身份: </span>
        <span v-if="userInfo.role == 1">管理员</span>
        <span v-else-if="userInfo.role == 2">主管</span>
        <span v-else-if="userInfo.role == 3">销售</span>
        <span v-else-if="userInfo.role == 4">日语老师</span>
        <span v-else-if="userInfo.role == 5">韩语老师</span>
        <span v-else-if="userInfo.role == 6">法语老师</span>
      </div>
      <div>
        <span>登录时间: </span>
        <span>
          {{
            $moment(userInfo.login_time * 1000).format("YYYY-MM-DD HH:mm:ss")
          }}</span
        >
      </div>
      <div>
        <span>登录IP: </span>
        <span> {{ userInfo.login_ip }}</span>
      </div>
      <div>
        <el-button type="primary" @click="unpdateDialog = true"
          >修改头像</el-button
        >
      </div>

      <!-- 上传素材 -->
      <el-dialog
        title="修改头像"
        :visible.sync="unpdateDialog"
        width="30%"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <form action="">
          <input
            type="file"
            name="image"
            id=""
            ref="kkk"
            @change="shangchuan"
            accept="image/*"
          />
        </form>
        <!-- <img src="" alt="" /> -->
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="goUnpdate" :disabled="isGoUnpdate"
            >立即修改</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getInfo } from "@/api/user";
import $http from "@/utils/request";
export default {
  data() {
    return {
      userInfo: [],
      unpdateDialog: false,
      fullscreenLoading: false,
      isGoUnpdate: true
    };
  },
  methods: {
    getUserInfo() {
      getInfo().then(res => {
        this.userInfo = res.data;
      });
    },
    shangchuan() {
      this.isGoUnpdate = false;
      let flie = this.$refs.kkk.files[0];
      let formdata = new FormData();
      formdata.append("avatar", flie);
      if (flie.size / 1024 / 1024 > 8) {
        this.$message.error("上传的头像不能大于 8M ");
        this.isGoUnpdate = true;
        return;
      }
      return formdata;
    },
    goUnpdate() {
      this.fullscreenLoading = true;
      const formdata = this.shangchuan();
      $http
        .post("/admin/base/rUserAvatar", formdata, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(res => {
          if (res.code !== 1) {
            this.fullscreenLoading = false;
            this.$message.error("修改头像失败");
          }
          this.$message.success("修改头像成功");
          this.fullscreenLoading = false;
          this.unpdateDialog = false;
          this.isGoUnpdate = true;
          this.getUserInfo();
        });
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
  color: #3bb08d;
}
img {
  width: 200px;
  height: 200px;
}
div {
  line-height: 60px;
  font-size: 24px;
}
</style>
